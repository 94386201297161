<template>
  <div class="invite">
    <SubTitle>邀请友商加入联盟</SubTitle>
    <div class="content">
      <div class="left">
        <div class="text-center mb-8">选择有备友商</div>
        <div class="add_box">
          <div class="search">
            <Search
              :formData="addObj"
              placeholderText="公司名称/联系人/联系电话"
              @handleQuery="addQuery"
            >
              <template slot="right">
                <SelectArea
                  checkStrictly
                  size="small"
                  class="area"
                  placeholder="地区筛选"
                  @change="addAreaChange"
                />
              </template>
            </Search>
          </div>

          <div class="body">
            <div class="item" v-for="(item, index) in ubeiData" :key="index">
              <div>
                {{ item.name }} / {{ item.contacts || "-" }} /
                {{ item.phone || "-" }}
              </div>
              <el-button
                type="text"
                size="mini"
                :disabled="item.disabled"
                @click="handleAdd(item)"
              >
                添加
              </el-button>
            </div>
            <div class="noData" v-if="!ubeiData.length">
              暂无数据
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <div class="button">
          <span>➠</span>
        </div>
        <div class="button left_arrow">
          <span>➠</span>
        </div>
      </div>
      <div class="right">
        <div class="text-center mb-8">已添加友商</div>
        <div class="add_box">
          <div class="search">
            <Search
              :formData="addedObj"
              placeholderText="公司名称/联系人/联系电话"
              @handleQuery="addedQuery"
            >
              <!-- <template slot="right">
                <SelectArea
                  checkStrictly
                  size="small"
                  class="area"
                  placeholder="地区筛选"
                  @change="addedAreaChange"
                />
              </template> -->
            </Search>
          </div>

          <div class="body">
            <div class="item" v-for="(item, index) in addedData" :key="index">
              <div>
                {{ item.name }} / {{ item.contacts || "-" }} /
                {{ item.phone || "-" }}
              </div>
              <el-button
                type="text"
                class="text-danger"
                size="mini"
                @click="handleRemove(item)"
              >
                移除
              </el-button>
            </div>
            <div class="noData" v-if="!addedData.length">
              暂无数据
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubTitle from "@/components/manageCom/SubTitle.vue";
import Search from "./Search.vue";
import SelectArea from "@/components/selectArea/SelectArea.vue";
import { postCompanyList } from "@/api/alliance";

export default {
  name: "InviteJoin",
  components: {
    SubTitle,
    Search,
    SelectArea,
  },
  props: {
    excludeAllianceId: {
      type: Number,
      default: 0,
    },
    isReset: {
      type: Boolean,
    },
  },
  data() {
    return {
      addObj: {
        keyword: "",
        areaId: 0,
        page: 1,
        pageSize: 500,
      },
      addedObj: {
        keyword: "",
        areaId: 0,
      },
      ubeiData: [],
      addedData: [], //已添加友商（界面显示）
      addedAllList: [], //已添加友商（all）
    };
  },
  watch: {
    addedAllList: {
      handler(val) {
        this.$emit(
          "change",
          val.map((item) => item.id)
        );
      },
      deep: true,
    },
    isReset(val) {
      if (val) {
        this.resetFn();
      }
    },
  },
  methods: {
    //重置
    resetFn() {
      this.addObj = {
        keyword: "",
        areaId: 0,
        page: 1,
        pageSize: 500,
      };
      this.addedObj = {
        keyword: "",
        areaId: 0,
      };
      this.ubeiData = [];
      this.addedData = []; //已添加友商（界面显示）
      this.addedAllList = []; //已添加友商（all）
    },
    //有备友商搜索
    addQuery() {
      let params = {
        ...this.addObj,
        noself: true, //是否排除自己，创建联盟邀请成员时，传true
        excludeAllianceId: this.excludeAllianceId, //排除制定联盟ID的成员，在已创建联盟里再邀请成员，传当前联盟ID
      };
      postCompanyList(params).then((res) => {
        this.ubeiData = res?.rows || [];
        this.addedQuery();
      });
    },
    //有备友商地区筛选
    addAreaChange(areaObj) {
      this.addObj.areaId = areaObj.areaId;
      this.addQuery();
    },
    //添加
    handleAdd(item) {
      this.addedAllList.push(item);
      this.addedQuery();
    },
    //已添加友商搜索
    addedQuery() {
      let keyword = this.addedObj.keyword;
      if (!keyword) {
        this.addedData = [...this.addedAllList];
      } else {
        this.addedData = this.addedAllList.filter(
          (item) =>
            (item.name + item.contacts + item.phone).indexOf(keyword) > -1
        );
      }
      for (let i = 0; i < this.ubeiData.length; i++) {
        if (this.addedAllList.length) {
          for (let j = 0; j < this.addedAllList.length; j++) {
            if (this.ubeiData[i].id === this.addedAllList[j].id) {
              this.ubeiData[i].disabled = true;
              break;
            } else {
              this.ubeiData[i].disabled = false;
            }
          }
        } else {
          this.ubeiData[i].disabled = false;
        }
      }
    },
    //已添加友商地区筛选
    addedAreaChange(areaObj) {
      this.addedObj.areaId = areaObj.areaId;
    },
    //移除
    handleRemove(item) {
      let copyData = JSON.parse(JSON.stringify(this.addedAllList));
      for (let i = 0; i < copyData.length; i++) {
        if (item.id === copyData[i].id) {
          this.addedAllList.splice(i, 1);
        }
      }
      this.addedQuery();
    },
  },
};
</script>

<style lang="less" scoped>
.invite {
  // height: 100%;
  // padding: 10px 20px;
  .content {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .left,
    .right {
      width: 570px;
      height: 100%;
    }
    .center {
      margin: 0 40px;
      width: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .button {
        width: 40px;
        height: 40px;
        background: #dbdbdb;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
      }
      .left_arrow {
        transform: rotate(180deg);
        margin-top: 80px;
      }
    }
    .add_box {
      height: 326px;
      border: 1px solid #dcdfe6;
      display: flex;
      flex-direction: column;
      .search {
        border-bottom: 1px solid #dcdfe6;
      }
      .body {
        flex: 1;
        padding: 0 10px;
        overflow: auto;
        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          border-bottom: 1px solid #dcdfe6;
          .text-danger:hover {
            color: #f56c6ca6;
          }
        }
        .noData {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #999;
        }
      }
    }
  }
}
</style>
