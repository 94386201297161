<template>
  <div class="create">
    <div class="head">创建联盟</div>
    <div class="content">
      <AllianceInfoEdit
        @change="allianceChange"
        @check="checked"
        ref="allianceEdit"
      />
      <div class="divide"></div>
      <InviteJoin class="invite" @change="membersChange"/>
    </div>
    <div class="footer">
      <el-button size="small" @click="handleCancle">取 消</el-button>
      <el-button size="small" type="primary" @click="handleSure"
        >确 定</el-button
      >
    </div>
  </div>
</template>

<script>
import AllianceInfoEdit from "../components/AllianceInfoEdit.vue";
import InviteJoin from "../components/InviteJoin.vue";
import { createAlliance } from "@/api/alliance";

export default {
  name: "CreateAlliance",
  components: {
    AllianceInfoEdit,
    InviteJoin,
  },
  data() {
    return {
      allianceInfo: {
        logoUrl: "",
        institution:
          "<h2>联盟管理制度</h2><p>&nbsp; &nbsp; &nbsp; 为了充分发挥联盟各成员相互之间合作的优势，促进联盟汽配集约化、网络化经营，提升联盟配件企业经营水平。根据国家有关法律、法规，经过相互平等友好协商，共同就结成联盟形成以下联盟管理制度：<br/></p><p>第一条&nbsp;&nbsp; &nbsp;&nbsp;联盟内共享库存在满足成员需求,并价格合适情况下，成员应在联盟内采购。</p><p>第二条&nbsp;&nbsp; &nbsp;&nbsp;平台价格为成员间交易上限，不得以缺货涨价等原因拒绝销售。</p><p>第三条&nbsp;&nbsp; &nbsp;&nbsp;平台经营配件不得以次充好，知假买假。如确实因采购时不慎出现货物假冒，提供进货凭证可酌情处理。</p><p>第四条&nbsp;&nbsp; &nbsp;&nbsp;平台默认每月15日为结帐期，超期不结账成员可以向管委会投诉。说明情况可适当延期并确认结算时间，再次逾期则给予处罚。</p><p>第五条&nbsp;&nbsp; &nbsp;&nbsp;平台成员上传库存需准确，不得以库存有误拒绝销售。</p><p>第六条&nbsp;&nbsp; &nbsp;&nbsp;一个月内成员间退货在无损不影响再次销售的情况下友好协商处理。</p><p>第七条&nbsp;&nbsp; &nbsp;&nbsp;因市场价格变动，成员应主动向管委会通报。</p><p>第八条&nbsp;&nbsp; &nbsp;&nbsp;成员不得泄露联盟库存及价格信息，一经发现罚款5000元，二次发生开除出联盟。</p><p>第九条&nbsp;&nbsp; &nbsp;&nbsp;联盟会议及集体活动提前通知，可提前请假。</p><p>第十条&nbsp;&nbsp; &nbsp;&nbsp;成员有义务对不良商户在联盟内通报。</p><p>成员违反上述制度者，以管委会处理决定为准，管委会拥有最终解释权。</p>",
      },
      flag: false,
    };
  },
  methods: {
    //取消
    handleCancle() {
      this.$router.push("/allianceManage/list");
    },
    //确定
    handleSure() {
      this.$refs.allianceEdit.toCheck(); //校验
    },
    checked(val) {
      if (val) {
        if (!this.allianceInfo.institution) {
          this.$message.warning({
            message: "联盟制度不能为空",
            showClose: true,
          });
          return;
        }
        let params = {
          ...this.allianceInfo,
          accountPeriod: Number(this.allianceInfo.accountPeriod),
        };
        createAlliance(params).then((res) => {
          console.log("res", res);
          this.$message.success({
            message: "创建联盟成功",
            showClose: true,
          });
          this.$router.push("/allianceManage/list");
        });
      }
    },
    allianceChange(val) {
      this.allianceInfo = { ...this.allianceInfo, ...val };
    },
    membersChange(val){
      this.allianceInfo.memberIds = val;
    }
  },
};
</script>

<style lang="less" scoped>
.create {
  height: 100%;
  display: flex;
  flex-direction: column;
  .head {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    background: #eeeeee;
    border-bottom: 1px solid #e9e9e9;
  }
  .content {
    flex: 1;
    overflow: auto;
    .divide {
      border-top: 2px dashed #e9e9e9;
      padding: 5px;
    }
    .invite {
      padding: 10px 20px;
    }
  }
  .footer {
    text-align: center;
    border-top: 1px solid #e9e9e9;
    padding: 10px;
  }
}
</style>
